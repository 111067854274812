<template>
  <div class="wrapper">
    <button class="qu_button" @click="start_qu" v-if="press_to_start">أضغط هنا اذا كنت ترغب بالتسجيل في الشركة</button>

    <div v-for="(item,index) in qu" :key="index" v-if="!complete_qu">
        <div class="qu" v-if="item.show">
          <div class="qu_head">
            <p>
              <span class="f-17">{{ item.qu_n }} : </span>
              <span class="f_normal f-17">{{ item.qu }}</span>
            </p>
          </div>
          <div class="">
            <button class="qu_button" @click="nextStep(item,index)" v-for="ans in item.answer" :key="item.id">{{ ans.text }}</button>
          </div>
        </div>
    </div>

    <!-- loading before form -->
    <LoadingBefore v-if="complete_qu"/>


  </div>
</template>

<script>
import LoadingBefore from "@/components/LoadingBeforeRegister/LoadingBefore";
import Form from "@/components/theForm";
export default {
  components: {Form, LoadingBefore},
  data:() => ({
    press_to_start: true,
    complete_qu: false,
    show_form: false,
    qu: [
      {
        step: 1,
        qu_n: 'السؤال الاول',
        qu: 'هل قمت بإستثمار اموالك من قبل ؟',
        answer:[
          {
            text: 'نعم',
            rules: true,
          },
          {
            text: 'لا',
            rules: true,
          }
        ],
        show: false,
      },
      {
        step: 2,
        qu_n: 'السؤال الثاني',
        qu: 'ماهي جنسيتك ؟',
        answer:[
          {
            text: 'مواطن',
            rules: true,
          },
          {
            text: 'وافد',
            rules: true,
          }
        ],
        show: false,
      },
      {
        step: 3,
        qu_n: 'السؤال الثالث',
        qu: 'هل تستطيع العمل ساعه يوميا من جوالك لكى تراقب صفقاتك وارباحك ؟',
        answer:[
          {
            text: 'نعم لدي بعض الوقت',
            rules: true,
          },
          {
            text: 'لا ليس لدي اي وقت',
            rules: true,
          }
        ],
        show: false,
      },
    ]
  }),
  methods: {
    nextStep(item) {
      item.show = false;
      if( this.qu[item.step] === undefined ) {
        this.complete_qu = true;
        return false
      }
      this.qu[item.step].show = true;
    },
    start_qu() {
      this.qu[0].show = true
      this.press_to_start = false;
    }
  }
}
</script>